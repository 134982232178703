exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affiliate-marketing-glossary-index-js": () => import("./../../../src/pages/affiliate-marketing-glossary/index.js" /* webpackChunkName: "component---src-pages-affiliate-marketing-glossary-index-js" */),
  "component---src-pages-avante-network-js": () => import("./../../../src/pages/avante-network.js" /* webpackChunkName: "component---src-pages-avante-network-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-search-js": () => import("./../../../src/pages/blog/search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-career-bdr-js": () => import("./../../../src/pages/career/bdr.js" /* webpackChunkName: "component---src-pages-career-bdr-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-career-integration-manager-js": () => import("./../../../src/pages/career/integration-manager.js" /* webpackChunkName: "component---src-pages-career-integration-manager-js" */),
  "component---src-pages-career-technical-support-agent-js": () => import("./../../../src/pages/career/technical-support-agent.js" /* webpackChunkName: "component---src-pages-career-technical-support-agent-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-eli-js": () => import("./../../../src/pages/eli.js" /* webpackChunkName: "component---src-pages-eli-js" */),
  "component---src-pages-igaming-affiliate-software-js": () => import("./../../../src/pages/igaming-affiliate-software.js" /* webpackChunkName: "component---src-pages-igaming-affiliate-software-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-lead-distribution-js": () => import("./../../../src/pages/lead-distribution.js" /* webpackChunkName: "component---src-pages-lead-distribution-js" */),
  "component---src-pages-lp-ru-js": () => import("./../../../src/pages/lp-ru.js" /* webpackChunkName: "component---src-pages-lp-ru-js" */),
  "component---src-pages-partner-platform-js": () => import("./../../../src/pages/partner-platform.js" /* webpackChunkName: "component---src-pages-partner-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-simon-js": () => import("./../../../src/pages/simon.js" /* webpackChunkName: "component---src-pages-simon-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-glossary-post-js": () => import("./../../../src/templates/glossary-post.js" /* webpackChunkName: "component---src-templates-glossary-post-js" */)
}

